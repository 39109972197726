<template>
  <div class="dashboard">
    <h1>Dashboard</h1>
    <v-container>
      <v-row>
        <v-col>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-subtitle>Sent Emails</v-subtitle>
                  <div class="text-h2" style="font-weight: 400">
                    {{ totalSent() }}
                  </div>
                </v-col>
                <v-col>
                  <v-subtitle>Email Opens</v-subtitle>
                  <div class="text-h2" style="font-weight: 400">
                    {{ totalOpens() }}
                  </div>
                </v-col>
                <v-col>
                  <v-subtitle>Replies</v-subtitle>
                  <div class="text-h2" style="font-weight: 400">
                    {{ totalReplies() }}
                  </div>
                </v-col>
                <v-col>
                  <v-subtitle>Leads</v-subtitle>
                  <div class="text-h2" style="font-weight: 400">
                    {{ totalLeads() }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-card flat>
            <v-card-text>
              <v-sheet style="position: relative">
                <v-sparkline
                  :value="stats.map((s) => s.totalSent)"
                  :labels="
                    stats.map(
                      (s) =>
                        `${s.totalSent} / ${s.totalUniqueOpens} / ${s.totalReplies}`
                    )
                  "
                  color="secondary"
                  height="100"
                  padding="45"
                  line-width="2"
                  stroke-linecap="round"
                  smooth
                >
                </v-sparkline>
                <v-sparkline
                  class="stackSpark"
                  style="top: 20px"
                  :value="stats.map((s) => s.totalUniqueOpens)"
                  color="primary"
                  height="100"
                  padding="45"
                  line-width="2"
                  stroke-linecap="round"
                  smooth
                >
                </v-sparkline>
                <v-sparkline
                  class="stackSpark"
                  style="top: 40px"
                  :value="stats.map((s) => s.totalReplies)"
                  color="pink"
                  height="100"
                  padding="45"
                  line-width="2"
                  stroke-linecap="round"
                  smooth
                >
                </v-sparkline>
              </v-sheet>
            </v-card-text>
            <v-card-text class="text-center">
              <div class="text-h6">Sent / Opened / Replied Trend</div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card style="padding: 64px" flat>
            <v-progress-circular
              :size="100"
              :width="15"
              :value="openRate()"
              color="primary"
              class="mx-3"
            >
              {{ `${openRate()}%` }}
            </v-progress-circular>
            <v-progress-circular
              :size="100"
              :width="15"
              :value="replyRate()"
              color="pink"
              class="mx-3"
            >
              {{ `${replyRate()}%` }}
            </v-progress-circular>
            <v-progress-circular
              :size="100"
              :width="15"
              :value="conversionRate()"
              color="teal"
              class="mx-3"
            >
              {{ `${conversionRate()}%` }}
            </v-progress-circular>
            <v-card-text class="text-center">
              <div class="text-h6">Open / Reply / Lead Rate</div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { store } from "@/state/store.js";
export default {
  data() {
    return {
      stats: [],
      store,
    };
  },
  created() {
    this.loadDashboard();
  },
  computed: {
    selectedClient() {
      return this.store.selectedClient;
    },
  },
  watch: {
    selectedClient() {
      this.loadDashboard();
    },
  },
  methods: {
    openRate() {
      return parseFloat((this.totalOpens() / this.totalSent()) * 100).toFixed(
        2
      );
    },
    replyRate() {
      return parseFloat((this.totalReplies() / this.totalSent()) * 100).toFixed(
        2
      );
    },
    conversionRate() {
      return parseFloat((this.totalLeads() / this.totalSent()) * 100).toFixed(
        2
      );
    },
    totalSent() {
      return this.stats.reduce((sum, { totalSent }) => sum + totalSent, 0);
    },
    totalOpens() {
      return this.stats.reduce(
        (sum, { totalUniqueOpens }) => sum + totalUniqueOpens,
        0
      );
    },
    totalReplies() {
      return this.stats.reduce(
        (sum, { totalReplies }) => sum + totalReplies,
        0
      );
    },
    totalLeads() {
      return this.stats.reduce((sum, { totalLeads }) => sum + totalLeads, 0);
    },
    async loadDashboard() {
      try {
        const statsResponse = await this.$http.get(
          `/stats/?_filter=${this.selectedClient}`
        );
        this.stats = statsResponse.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.text--small {
  font-size: 12px;
}
.stackSpark {
  position: absolute;
  left: 0;
}
</style>
